import { getIdentityAuth } from '@guardian/identity-auth-frontend';
export async function isSignedInAuthState() {
    return getIdentityAuth()
        .isSignedInWithAuthState()
        .catch((e) => {
        // eslint-disable-next-line no-console -- we want to log the error to console, not Sentry
        console.error(e);
        return {
            isAuthenticated: false,
            accessToken: undefined,
            idToken: undefined,
        };
    });
}
export const getOptionsHeaders = (authStatus) => {
    return {
        headers: {
            Authorization: `Bearer ${authStatus.accessToken.accessToken}`,
            'X-GU-IS-OAUTH': 'true',
        },
    };
};
export async function getAuthState() {
    const authState = await isSignedInAuthState();
    return authState;
}
export function getSignedInStatus(authState) {
    if (authState.isAuthenticated) {
        return {
            kind: 'SignedIn',
            accessToken: authState.accessToken,
            idToken: authState.idToken,
        };
    }
    return { kind: 'SignedOut' };
}
export const isUserLoggedIn = () => getAuthStatus().then((authStatus) => authStatus.kind === 'SignedIn' ? true : false);
export const getAuthStatus = async () => {
    const authState = await getAuthState();
    return getSignedInStatus(authState);
};
