import { isAdBlockInUse } from '@guardian/commercial';
import { log, startPerformanceMeasure } from '@guardian/libs';
import '../webpackPublicPath';
const loadSentryCreator = () => {
    /**
     * Downloading and initialising Sentry is asynchronous so we need a way
     * to ensure initialisation only happens once and to queue and report any
     * error that might happen while this script is loading
     */
    let initialised = false;
    const errorQueue = [];
    /**
     * Function that gets called when an error happens before Sentry is ready
     */
    const loadSentry = async (error, feature = 'unknown', tags, extras) => {
        const { endPerformanceMeasure } = startPerformanceMeasure('dotcom', 'sentryLoader', 'initialise');
        /**
         * Queue this error for later
         */
        if (error)
            errorQueue.push({ error, feature, tags, extras });
        /**
         * Only initialise once
         */
        if (initialised) {
            return;
        }
        initialised = true;
        /**
         * Make this call blocking. We are queing errors while we wait for this code to run
         * so we won't miss any and by waiting here we ensure we will never make calls we
         * expect to be blocked
         * Ad blocker detection can be expensive so it is checked here rather than in init
         * to avoid blocking of the init flow
         */
        const adBlockInUse = await isAdBlockInUse();
        if (adBlockInUse) {
            /**
             * Ad Blockers prevent calls to Sentry from working so don't try to load the lib
             */
            return;
        }
        /**
         * Dynamically load sentry.ts
         */
        const { reportError } = await import(
        /* webpackChunkName: "lazy" */
        /* webpackChunkName: "sentry" */ './sentry');
        /**
         * Replace the lazy loader stub with our custom error reporting function
         */
        window.guardian.modules.sentry.reportError = reportError;
        /**
         * Now that we have the real reportError function available,
         * report any queued errors
         */
        while (errorQueue.length) {
            const queuedError = errorQueue.shift();
            if (queuedError) {
                reportError(queuedError.error, queuedError.feature, queuedError.tags, queuedError.extras);
            }
        }
        log('dotcom', `Initialise Sentry in ${endPerformanceMeasure()}ms`);
    };
    return loadSentry;
};
/**
 * Set up error handlers to initialise and call Sentry when an error occurs.
 * If no error happens, Sentry is not loaded.
 */
const loadSentryOnError = () => {
    try {
        const loadSentry = loadSentryCreator();
        /**
         * This is how we lazy load Sentry. We setup custom functions and
         * listeners to initialise Sentry when an error happens
         *
         * Sentry will replace onerror and onunhandledrejection listeners
         * with its own handlers once initialised
         *
         * reportError is replaced by loadSentry
         */
        window.onerror = (message, url, line, column, error) => loadSentry(error);
        window.onunhandledrejection = (event) => event?.reason instanceof Error && loadSentry(event.reason);
        window.guardian.modules.sentry.reportError = (error, feature, tags, extras) => {
            loadSentry(error, feature, tags, extras).catch((e) => 
            // eslint-disable-next-line no-console -- fallback to console.error
            console.error(`loadSentryOnError error: ${String(e)}`));
        };
    }
    catch (e) {
        // eslint-disable-next-line no-console -- fallback to console.error
        console.error(`loadSentryOnError error: ${String(e)}`);
    }
};
export { loadSentryOnError };
